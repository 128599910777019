import React,{Component, Fragment} from 'react';
import {Link} from 'react-router-dom';

import logo from './../../images/logo.png';

class Header extends Component{
	componentDidMount() {

        // sidebar open/close

        var Navicon = document.querySelector('.navicon');
        var sidebarmenu = document.querySelector('.myNavbar ');

        function toggleFunc() {
            sidebarmenu.classList.toggle('show');
            Navicon.classList.toggle('open');
        }
        Navicon.addEventListener('click', toggleFunc);


        // Sidenav li open close
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }
		
		
        function checkLi(current) {
            navUl.forEach(el => (current !== el) ? el.classList.remove('open') : '');
            
			setTimeout(() => {
			
			current.classList.toggle('open');
			
			}, 100);
						
        }
	}
	render(){
		return(
			<Fragment>
				{/*  header  */}
				<header className="site-header header-transparent">
					<HeaderContent />					
				</header>
				{/*  header End  */}
			</Fragment>
		)
	}
}
function HeaderContent(){
	return(
		<>
			<div className="top-bar">
				<div className="container-fluid">
					<div className="row d-flex justify-content-md-between justify-content-center align-items-center">
						<div className="dlab-topbar-left">
							<ul>
								<li><i className="la la-phone-volume"></i> <a href="tel:+6691028383">+669 102 8383</a> </li>
								<li><i className="las la-map-marker"></i> Avenida Villa Marina, Sabalo Cerritos, Mazatlán, Sin.</li>
							</ul>
						</div>
						<div className="dlab-topbar-right">
							<ul>
								<li><i className="la la-clock"></i>  Lun - Dom 8:00 - 19:00</li>
								<li><i className="las la-envelope-open"></i> antonio@marinacerritos.mx</li>
							</ul>				
						</div>
					</div>
				</div>
			</div>
			{/*   main header  */}
			<div className="sticky-header main-bar-wraper navbar-expand-lg">
				<div className="main-bar clearfix ">
					<div className="container-fluid clearfix">
					{ /* website logo */ }
					<div className="logo-header mostion logo-dark">
						<Link to={"./"}><img src={logo} alt="MarinaCerritos | Los mejores departamentos de Mazatlán" /></Link>
					</div>
					{/* nav toggle button  */}
					<button className="navbar-toggler collapsed navicon justify-content-end " type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
						<span></span>
						<span></span>
						<span></span>
					</button>
					{/*extra nav  */}
					<div className="extra-nav">
						<div className="extra-cell">
							<a href="https://wa.me/526691028383" target="_blank" className="btn btn-primary btnhover13">Contactar Ahora</a>
						</div>
					</div>
					<div className="header-nav navbar-collapse collapse myNavbar justify-content-end" id="navbarNavDropdown">
						<div className="logo-header d-md-block d-lg-none">
							<Link to={"./"}><img src={logo} alt="MarinaCerritos | Los mejores departamentos de Mazatlán" /></Link>
						</div>
						<ul className="nav navbar-nav navbar">	
							<li className="active"><Link to={"./"}>Inicio</Link>
							</li>
							{/* <li className="active"><Link to={"/inversion"}>Inversión</Link>
							</li> */}
						</ul>
						<div className="dlab-social-icon">
							<ul>
								<li><Link to={"#"} className="site-button circle fa fa-facebook" ></Link></li>
								<li><Link to={"#"} className="site-button  circle fa fa-twitter" ></Link></li>
								<li><Link to={"#"} className="site-button circle fa fa-linkedin" ></Link></li>
								<li><Link to={"#"} className="site-button circle fa fa-instagram"></Link></li>
							</ul>
						</div>		
					</div>
				</div>
				</div>
			</div>
		</>
	)
} 
export {HeaderContent};
export default Header;