import React, { Component } from "react";
import Slider from "react-slick";

import  pic1 from './../../../images/gallery/carousel/pic1.jpg';
import thumb1 from './../../../images/gallery/carousel/thumb1.jpg';

import spec1 from './../../../images/plan/master1.JPG';
import spec2 from './../../../images/plan/master2.JPG';
import spec3 from './../../../images/plan/master3.JPG';
import spec4 from './../../../images/plan/master4.JPG';
import spec5 from './../../../images/plan/master5.JPG';
import spec6 from './../../../images/plan/master6.JPG';
import spec7 from './../../../images/plan/master7.jpg';
import spec8 from './../../../images/plan/master8.jpg';
import spec9 from './../../../images/plan/master9.jpg';
import spec10 from './../../../images/plan/master10.jpg';

const galleryBlog = [
	{ image: spec1,	 },
	{ image: spec2,	 },
	{ image: spec3,	 },
	{ image: spec4,	 },
	{ image: spec5,	 },
	{ image: spec6,	 },
	{ image: spec7,	 },
	{ image: spec8,	 },
	{ image: spec9,	 },
	{ image: spec10,	 },
]


export default class SwiperSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
		var settings = {		
			arrows: false,
			centerMode: true,
			
        };
    return (
      <div>
       
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
		  className="owl-carousel owl-theme owl-none owl-dots-none gallery-top "
		  {...settings}
        >
			
			{galleryBlog.map((item, index) => (
				<div className="item" key={index}>
					<div className="gallery-media">
						<img src={item.image} alt=""  style={{width:"100%"}}  />
						<h4 className="title">MarinaCerritos</h4>
					</div>
				</div>
			))}	
			
          
        </Slider>

        <Slider
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={5}
          swipeToSlide={true}
          focusOnSelect={true}
		  className="owl-carousel owl-theme owl-none owl-dots-none gallery-bottom container owl-btn-center-lr "
		  {...settings}
        >
          <div>
            
			<div className="item p-2" >
				<div className="gallery-box">
					<img src={spec1} alt="" style={{width:"100%"}} / >
				</div>
			</div>
				
          </div>
          <div>
            <div className="item p-2" >
				<div className="gallery-box">
					<img src={spec2} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
            <div className="item p-2" >
				<div className="gallery-box">
					<img src={spec3} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
           <div className="item p-2" >
				<div className="gallery-box">
					<img src={spec4} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
            <div className="item p-2" >
				<div className="gallery-box">
					<img src={spec5} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
          <div>
             <div className="item p-2" >
				<div className="gallery-box">
					<img src={spec6} alt="" style={{width:"100%"}} / >
				</div>
			</div>
          </div>
        </Slider>
      </div>
    );
  }
}

