import React,{ Fragment, Component} from 'react';
import WOW from 'wowjs';
import Header from './../../Layout/Header';
import Footer2 from './../../Layout/Footer2';
import PageTitle from './../../Layout/PageTitle';
import Mapview from './../../Element/Mapview';

//images/

// import bgimg from './../../../images/bg-view.png';
import bgimg from './../../../images/maricacon/footer-1.jpeg';

class ContactUs1 extends Component{
	constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

	handleSubmit(event) {
		console.log('entro',this.state);
		this.setState({value: ''});
		if (this.state.value !== '') {
			window.location.href = `https://wa.me/526691028383?text=${this.state.value}`; 
		}
    event.preventDefault();
	}
	
	render(){
		return(
			<Fragment>
				<div className="page-content bg-white">
					{/*  Inner Page Banner */}
					<section className="content-inner-1" data-content="SOBRE NOSOTROS">				
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-4 col-md-5 col-sm-12">
									<div className="section-head m-b30">
										<h2 className="title">¿TIENES ALGUNA PREGUNTA?</h2>
										<div className="dlab-separator bg-primary"></div>
										<h5 className="title-small">CONTACTANOS</h5>
									</div>
									<ul className="contact-question">
										<li>
											<i className="fa fa-map-marker"></i>
											<h4 className="title">Domicilio</h4>
											<p>Avenida Villa Marina, Sabalo Cerritos, Mazatlán, Sinaloa</p>
										</li>
										<li>
											<i className="fa fa-envelope-o"></i>
											<h4 className="title">Correo</h4>
											<p>Antonio@oigamevenden.con</p>
										</li>
										<li>
											<a href="tel:+6691238888" className="call-planner"><i className="la la-phone-volume"></i>+669 102 8383</a>
										</li>
									</ul>
								</div>
								<div className="col-lg-8 col-md-7 col-sm-12 m-b30">
									<form name="myform" className="contact-box dzForm p-a30 border-1" onSubmit={this.handleSubmit} noValidate>
										<h3 className="title-box">Escríbanos unas palabras sobre su proyecto y le prepararemos una propuesta en 24 horas.</h3>
										<div className="dzFormMsg m-b20"></div>
										<input type="hidden" value="Contact" name="dzToDo" />
										<div className="row">
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<div className="input-group">
														<input name="name" type="text" className="form-control" placeholder="Nombre Completo" />
													</div>
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<div className="input-group">
														<textarea  value={this.state.value} onChange={this.handleChange}  rows="4" className="form-control" placeholder="Cuéntanos tu proyecto o idea"></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group form-recaptcha">
													<div className="input-group">
														<div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
														<input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Por favor complete el Captcha" />
													</div>
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<button name="submit" type="submit" value="Submit" className="btn btn-primary btn-lg">Enviar!</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
					<div>				
						<img src={bgimg} className="bg-view" alt="Marina Cerritos | Mapa del sitio"/>
					</div>
				</div>
			</Fragment>
		)	
	}
}
export default ContactUs1;