import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import logo1 from './../../images/logo/logo1.png';
import logo2 from './../../images/logo/logo2.png';
import logo3 from './../../images/logo/logo3.png';
import logo4 from './../../images/logo/logo4.png';
import logowhite from './../../images/logo-white.png';
import logohersa from './../../images/maricacon/hersa.png';
import logooiga from './../../images/maricacon/oiga.png';


class Footer extends Component {
	render() {
		return (
			<Fragment>
				<footer className="site-footer" id="sidenav_footer">
					<div className="footer-top">
						<div className="container">
							<div className="row">
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.3s">
									<Footercol1 />
								</div>
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.6s">
									<Footercol2 />
								</div>
								<div className="col-md-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.9s">
									<Footercol3 />
								</div>
							</div>
						</div>
					</div>
					{/*  footer bottom part */}
					<div className="footer-bottom">
						<Footerbottom />
					</div>
				</footer>
			</Fragment>
		)
	}
}
function Footercol1() {
	return (
		<>
			<div className="widget widget_about">
				<div className="footer-logo">
					<Link to="./"><img src={logowhite} alt="MarinaCerritos | Mazatlán Logo" /></Link>
				</div>
				<p>Rodéate de energía fresca, comodidades de alta tecnología y un estilo elevado. Disfrute de comodidades extraordinarias, relájese en atractivos espacios sociales.</p>
				<div className="dlab-social-icon">
					<ul>
						<li><a href={"https://www.instagram.com/marina_cerritos_condominios"} target="_blank" className="fa fa-instagram" ></a></li>
						<li><a href={"https://www.facebook.com/marinacerritoscondominios"} target="_blank" className="fa fa-facebook mr-1" ></a></li>
					</ul>
				</div>
			</div>
		</>
	)
}

function Footercol2() {
	return (
		<>
			<div className="widget">
				<h5 className="footer-title">Contacto</h5>
				<ul className="contact-info-bx">
					<li><i className="las la-map-marker"></i><strong>Dirección</strong>  Avenida Villa Marina, Sabalo Cerritos, Mazatlán, Sin.</li>
					<li><i href="tel:+6691238888" className="las la-phone-volume"></i><strong>Teléfono : </strong> +669 102 8383</li>
				</ul>
			</div>
		</>
	)
}

function Footercol3() {
	return (
		<>
			<div className="widget widget-logo">
				<h5 className="footer-title">NUESTROS CANALES DE NEGOCIO</h5>
				<ul>
					<li><Link to={"#"}><img src={logohersa} width="100" alt="MarinaCerritos | Mazatlan - Logo Hersa Corporativo" /></Link></li>
					<li><a href={"https://oigamevenden.mx/"} target="_blank"><img src={logooiga} width="120" alt="MarinaCerritos | Oiga me venden, condominios" /></a></li>
					{/* <li><a href={"https://oigamevenden.mx/"} target="_blank"><p style={{color:'#fff',fontWeight:600,textAlign:'center'}}>TERRENOS</p></a></li> */}
				</ul>
				<h5 className="footer-title">PAGINAS OFICIALES</h5>
				<ul>
					<li><a href={"https://oigamevenden.mx/"} target="_blank"><p style={{color:'#fff',fontWeight:600}}>PROPIEDADES</p></a></li>
					<li><a href={"https://terrenosenmazatlan.com"} target="_blank"><p style={{color:'#fff',fontWeight:600}}>TERRENOS</p></a></li>
				</ul>
			</div>
		</>
	)
}

function Footerbottom() {
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-sm-12 text-md-left text-center"> <span>2021 <a style={{color:'#fff'}} href="https://www.trazolab.mx/" target="_blank" >Creado por Trazolab.mx</a> ©  </span> </div>
					{/* <div className="col-md-6 col-sm-12 text-md-right text-center"> 
						<div className="widget-link "> 
							<ul>
								<li><Link to={"/about-us-1"}> About</Link></li>
								<li><Link to={"/contact-us-1"}> Contact Us</Link></li>
								<li><Link to={"/privacy-policy"}> Privacy Policy</Link></li>
							</ul>
						</div>
					</div> */}
				</div>
			</div>
		</>
	)
}

export { Footercol1, Footercol2, Footercol3, Footerbottom };
export default Footer;