import React,{Fragment, Component } from 'react';
import map from './../../images/map2.JPG';
import sitemap1 from './../../images/maricacon/sitiomap-1.jpeg';
import sitemap2 from './../../images/maricacon/sitiomap-2.jpeg';


class Mapview extends Component{
	render(){
		return(
			<Fragment>
			<div className="map-view">
				<div className="row spno">
					<div className="col-md-6"><img src={sitemap1} alt="MarinaCerritos | Mapa de Amenidades en Mazatlán"/></div>
					<div className="col-md-6"><img src={sitemap2} alt="CerritosMarina | Mapa del sitio"/></div>
				</div>
			</div>	
			</Fragment>
		)
	}
}
export default Mapview;