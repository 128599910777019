import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Index1 from './Pages/Homepage/Index1';
import Aboutus2 from './Pages/Aboutus/Aboutus2';
import ContactUs1 from './Pages/Contact/ContactUs1';
import ContactUs2 from './Pages/Contact/ContactUs2';

//ScrollToTop
import ScrollToTop from './Element/ScrollToTop';

class Markup extends Component {
	render() {
		return (
			<BrowserRouter basename="/">
				<div className="page-wraper">
					<Switch>
						<Route path='/' exact component={Index1} />
						<Route path='/contact-us-1' exact component={ContactUs1} />
						<Route path='/contact-us-2' exact component={ContactUs2} />
						{/* <Route path='/inversion' exact component={Aboutus2} /> */}
					</Switch>
				</div>
				<ScrollToTop />
			</BrowserRouter>
		)
	}

}

export default Markup;