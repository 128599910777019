import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import icon1 from './../../../images/icons/icon-1.png';
import icon2 from './../../../images/icons/icon-2.png';
import icon3 from './../../../images/icons/icon-3.png';
import icon4 from './../../../images/icons/icon-4.png';
import plan1 from './../../../images/plan/pic.jpeg';
import plan2 from './../../../images/plan/pic.jpeg';
import malecon from './../../../images/plan/malecon.png';
import dorado from './../../../images/plan/dorado.png';

const SitePlanTab = (props) => {
	const [activeTab, setActiveTab] = useState('1');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	let resizeMargin = () => {
		//var  containerSize = document.querySelector('.container');
		//alert(window.innerWidth);	

		//var elmnt = document.getElementsByClassName('container');
		var screenWidth = window.innerWidth;
		if (screenWidth >= 1280) {
			var elmnt = document.querySelector('.container');
			var containerSize = elmnt.offsetWidth;
			//var containerSize = elmnt.clientWidth;

			//alert(screenWidth+' '+containerSize);
			var getMargin = (screenWidth - containerSize) / 2;
			//$('.setResizeMargin').css('margin-left',getMargin);
			document.getElementsByClassName('setResizeMargin')[0].setAttribute("style", 'margin-left:' + getMargin + 'px');
		}
	};
	/*window.innerWidth.length>0 && window.innerWidth >= 1280
	
	
	var getMargin = (screenWidth - containerSize)/2;
	
	('.setResizeMargin').css('margin-left',getMargin);
};*/
	useEffect(() => {
		resizeMargin();
	}, []);


	return (
		<Fragment>
			<div className="setResizeMargin master-plan">
				<div className="row">
					<div className="col-md-12 col-lg-6">
						<div className="section-head">
							<h2 className="title" >PLAN DEL SITIO Y PLAN MAESTRO</h2>
							<div className="dlab-separator bg-primary"></div>
							<p>Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop, rodeadas de áreas verdes, amenidades y muy cerca de la playa.</p>
							<p>50 departamentos por edificio, dividido en 5 niveles, con elevador.</p>

						</div>
						<div className="row">
							<div className="col-md-4 col-sm-4">
								<ul className="nav plan-tabs" id="myTab" role="tablist">
									<li className="nav-item " >
										<a className={classnames({ active: activeTab === '1' }) + ' nav-link'} onClick={() => { toggle('1'); }}
											id="main-tab" data-toggle="tab" role="tab" aria-controls="main" aria-selected="true">Modelo Faro</a>
									</li>
									<li className="nav-item">
										<a className={classnames({ active: activeTab === '2' }) + ' nav-link'} onClick={() => { toggle('2'); }}
											id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false">Modelo Malecón</a>
									</li>
									<li className="nav-item">
										<a className={classnames({ active: activeTab === '3' }) + ' nav-link'} onClick={() => { toggle('3'); }}
											id="contact-tab" data-toggle="tab" role="tab" aria-controls="contact" aria-selected="false">Modelo Dorado</a>
									</li>
								</ul>
							</div>
							<div className="col-md-8 col-sm-8">
								<TabContent activeTab={activeTab}>
									<TabPane tabId="1">
										<div className="plan-contant ">
											<div className="section-head">
												<h2 className="title">Modelo Faro </h2>
												<h5 className="title">Tipo Loft </h5>
												<p>Equipado con: Aire acondicionado, Cocina integral, Closets.</p>
												<div className="row">
													<div className="col-md-6">
														<h4 className="mb-lg-2">(52m2)</h4>
														<p>Frente a alberca</p>
													</div>
													<div className="col-md-6">
														<h4 className="mb-lg-2">(46m2)</h4>
														<p>Balcón o terraza frente área jardínada</p>
													</div>
												</div>
												<h2>Desde: $2'840,000</h2>
											</div>
											<ul className="flat-plan">
												<li>
													<img src={icon1} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>46</h3>
													<span>M2</span>
												</li>
												<li>
													<img src={icon2} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>01</h3>
													<span>Baño</span>
												</li>
												<li>
													<img src={icon3} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>01</h3>
													<span>Recamaras</span>
												</li>
												<li>
													<img src={icon4} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>01</h3>
													<span>Balcon</span>
												</li>
											</ul>
											<h2>Aparta con: $50,000</h2>
										</div>
									</TabPane>
									<TabPane tabId="2">
										<div className="plan-contant ">
											<div className="section-head">
												<h2 className="title">Modelo Malecón</h2>
												<p>Equipado con: Aire acondicionado, Cocina integral, Closets. Terraza en recámara principal y sala.</p>
												<div className="row">
													<div className="col-md-6">
														<h4 className="mb-lg-2">(86m2) </h4>
														<p>Frente a area jardinera</p>
													</div>
													<div className="col-md-6">
														<h4 className="mb-lg-2">(86m2) </h4>
														<p>Frente a alberca</p>
													</div>
												</div>
												<h2>Desde: $4'290,000</h2>
											</div>
											<ul className="flat-plan">
												<li>
													<img src={icon1} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>86</h3>
													<span>M2</span>
												</li>
												<li>
													<img src={icon2} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>02</h3>
													<span>Baño</span>
												</li>
												<li>
													<img src={icon3} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>02</h3>
													<span>Recamaras</span>
												</li>
												<li>
													<img src={icon4} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop" />
													<h3>01</h3>
													<span>Balcon</span>
												</li>
											</ul>
											<h2>Aparta con: $50,000</h2>
										</div>
									</TabPane>
									<TabPane tabId="3">
									<div className="plan-contant ">
											<div className="section-head">
												<h2 className="title">Modelo Dorado</h2>
												<h4 className="mb-lg-2">(110m2)</h4>
												<p>Equipado con: Aire acondicionado, Cocina integral, Closets. Recámara principal con acceso independiente, Terraza o balcón en recámaras y sala/comedor, 2 Balcónes o terrazas en dirección a área jardinada o alberca.</p>
												<h2>Desde: $4'890,000</h2>
											</div>
											<ul className="flat-plan">
												<li>
													<img src={icon1} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop,Terraza o balcón en recámaras" />
													<h3>110</h3>
													<span>M2</span>
												</li>
												<li>
													<img src={icon2} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop, Recámara principal con acceso independiente" />
													<h3>02</h3>
													<span>Baño</span>
												</li>
												<li>
													<img src={icon3} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop, Aire acondicionado, Cocina integral" />
													<h3>02</h3>
													<span>Recamaras</span>
												</li>
												<li>
													<img src={icon4} alt="MarinaCerritos | Marina cerritos es un desarrollo en condominio de 8 torres con RoofTop, Balcónes o terrazas en dirección a área jardinada o alberca" />
													<h3>02</h3>
													<span>Balcon</span>
												</li>
											</ul>
											<h2>Aparta con: $50,000</h2>
										</div>
									</TabPane>
									
								</TabContent>
							</div>
						</div>
					</div>
					<div className="col-md-12 col-lg-6">
						<TabContent activeTab={activeTab} className="plan-preview" >
							<TabPane tabId="1">
								<div className="tab-pane fade show active" role="tabpanel" aria-labelledby="main-tab">
									<img src={plan1} alt="Marina Cerritos | Vive tu inversión en Mazatlán" />
								</div>
							</TabPane>
							<TabPane tabId="2">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="profile-tab">
									<img src={malecon} alt="Marina Cerritos | Desarrollo con mas amenidades en Mazatlán" />
								</div>
							</TabPane>
							<TabPane tabId="3">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="contact-tab">
									<img src={dorado} alt="Marina Cerritos | MARINA CERRITOS es un desarrollo en condominio de 8 torres con roof top" />
								</div>
							</TabPane>
							<TabPane tabId="4">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="contact-tab">
									<img src={plan2} alt="Marina Cerritos | Departamentos en Mazatlán con inversión" />
								</div>
							</TabPane>
							<TabPane tabId="5">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="contact-tab">
									<img src={plan1} alt="Marina Cerritos | Departamento equipado con un SISTEMA INTELIGENTE" />
								</div>
							</TabPane>
							<TabPane tabId="6">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="contact-tab">
									<img src={plan2} alt="Marina Cerritos | Departamentos en Mazatlán" />
								</div>
							</TabPane>
							<TabPane tabId="7">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="contact-tab">
									<img src={plan1} alt="Marina Cerritos | Inversión en Mazatlán" />
								</div>
							</TabPane>
							<TabPane tabId="8">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="contact-tab">
									<img src={plan2} alt="Marina Cerritos | Departamentos en Mazatlán" />
								</div>
							</TabPane>
							<TabPane tabId="9">
								<div className="tab-pane fade show" role="tabpanel" aria-labelledby="contact-tab">
									<img src={plan1} alt="Marina Cerritos | Departamentos en Mazatlán" />
								</div>
							</TabPane>

						</TabContent>
					</div>
				</div>
			</div>
		</Fragment>
	)

}
export default SitePlanTab;