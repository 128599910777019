import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import { Accordion, Card } from 'react-bootstrap';


class SpecificationsBlog extends Component {
	componentDidMount() {
		new WOW.WOW().init();

		//SPECIFICATIONS Left Side hover 
		var faqCardHeader = document.querySelectorAll('.faqSpecifications .card-header a');
		var faqMedia = document.querySelectorAll('.faq-media img');

		var fch = [].slice.call(faqCardHeader);
		var fcMedia = [].slice.call(faqMedia);


		for (var y = 0; y < fch.length; y++) {
			fch[y].addEventListener('mouseenter', function () {
				galleryActive(this);
			});
		}

		function galleryActive(current) {
			fcMedia.forEach(el => el.classList.remove('active'));

			setTimeout(() => {
				var dataImageBx = current.getAttribute('data-image-bx');
				document.querySelector('#' + dataImageBx).classList.add('active');
			}, 100);
		}
	}
	render() {
		return (
			<Fragment>
				<div className="col-md-4 col-lg-4 col-xl-3 col-xxxl-2 faq-list">
					{/* <div className="text-right mb-4 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">
						<Link to={"./blog-grid"} className="btn btn-primary">View All Services</Link>
					</div> */}
					<Accordion defaultActiveKey="0" className="faqSpecifications">
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="0" data-image-bx="Capmap1">
									Albercas
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="0" className="collapse faq-content">
								<Card.Body >
									Albercas, distribuidas por todo el complejo.
									<div><strong>9</strong> albercas</div>
									<div><strong>1</strong> alberca con olas</div>
									<div><strong>1</strong> tirolesa</div>
									<div><strong>14</strong> albercas infantiles</div>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="1" data-image-bx="Capmap2">
									Gimnasio
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="1" className="collapse faq-content">
								<Card.Body >
									<div><strong>8</strong> Gimnasios, <strong>1</strong> privado por edificio.</div>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="2" data-image-bx="Capmap3">
									Area Infantil
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="2" className="collapse faq-content">
								<Card.Body >
									<div><strong>8</strong> areas infantiles, <strong>1</strong> privada por edificio.</div>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="3" data-image-bx="Capmap4">
									Coworking
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="3" className="collapse faq-content">
								<Card.Body >
									<div><strong>8</strong> Coworking, <strong>1</strong> privada por edificio.</div>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="4" data-image-bx="Capmap5">
								Áreas de Asadores
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="4" className="collapse faq-content">
								<Card.Body >
									<div><strong>8</strong> Áreas de Asadores <strong>1</strong> de uso común Distribuidas en todo el complejo.</div>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="5" data-image-bx="Capmap6">
									Mesas con Palapas
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="5" className="collapse faq-content">
							<Card.Body >
									<div><strong>8</strong> mesas con palapas, Distribuidas en todo el complejo </div>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="6" data-image-bx="Capmap7">
								Ciclovía
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="6" className="collapse faq-content">
								<Card.Body >Ejercitate en nuestra Ciclovía perimetral dentro del complejo.</Card.Body>
							</Accordion.Collapse>
						</Card>
						<Card >
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="7" data-image-bx="Capmap8">
									Elevador
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="7" className="collapse faq-content">
								<Card.Body >UN ELEVADOR CAMILLERO POR EDIFICIO CON CAPACIDAD PARA 12 personas y espacio suficiente para transportar tus muebles cómodamente </Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="8" data-image-bx="Capmap9">
									Roof Top
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="8" className="collapse faq-content">
								<Card.Body >1 privado por edificio en el que encontrarán gimnasio, sala de CoWorking, área infantil y área de convivencia</Card.Body>
							</Accordion.Collapse>
						</Card>

						<Card>
							<Card.Header >
								<Accordion.Toggle as={Link} className="collapsed" variant="link" eventKey="9" data-image-bx="Capmap10">
									Salón para Eventos
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey="9" className="collapse faq-content">
								<Card.Body >Capacidad para 50 personas</Card.Body>
							</Accordion.Collapse>
						</Card>

					</Accordion>
				</div>
			</Fragment>
		)
	}
}

export default SpecificationsBlog;
