import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import  pic1 from './../../images/amenities/pic1.jpg';
import  ame1 from './../../images/maricacon/pagina/cocina-2.jpg';
import  ame2 from './../../images/maricacon/pagina/comedor.jpg';
import  ame3 from './../../images/maricacon/pagina/recamara.jpg';
import  ame4 from './../../images/maricacon/pagina/recamara.jpg';
import  ame6 from './../../images/maricacon/pagina/sala.jpg';
import  ame7 from './../../images/maricacon/pagina/sala-2.jpg';
import  ame8 from './../../images/amenities/ame-8.JPG';
import  ame9 from './../../images/amenities/ame-9.JPG';

const latestBlog = [
	{ image: ame1, name: 'Cocina'	 },
	{ image: ame2, name: 'Comedor'	 },
	{ image: ame6, name: 'Recamara'	 },
	{ image: ame6, name: 'Sala'	 },
	{ image: ame7, name: 'Sala'	 },
]
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick} style={{zIndex:1 }}/>
	</div>
  );
}

class Slider1 extends Component{
	render(){
		var settings = {		
			arrows: false,
			centerMode: true,
			centerPadding: "500px",
            slidesToShow: 1,
			speed: 3000,
			navSpeed: 3000,		
            infinite: true,
			autoplay: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1920,
				  settings: {
					slidesToShow: 1,
					centerPadding: "400px",
				  }
				},
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
					centerPadding: "250px",
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
					centerPadding: "250px",
				  }
				},
				{
				  breakpoint: 768,
				  settings: {
					slidesToShow: 1,
					centerPadding: "200px",
				  }
				},
				{
				  breakpoint: 600,
				  settings: {
					slidesToShow: 1,
					centerPadding: "90px",
				  }
				}
			]
        };
		return(
			
				<Slider className="amenities-carousel owl-carousel owl-btn-center-lr " {...settings}>
					{latestBlog.map((item, index) => (
						<div className="items" key={index}>
							<div className="amenit-box" >
								<div className="media">
									<Link to={"#"} style={{width:"100%" }}><img src={item.image} alt="MarinaCerritos | Departamentos en Mazatlán" style={{width:"100%" }}/></Link>
								</div>
								<div className="info">
									<h5 className="title">
										<i className="ti-home"></i>
										<span>{item.name}</span>
									</h5>
								</div>
							</div>
						</div>
					))}	
				</Slider>
			
				
		)
		
	}
	
}

export default Slider1;
						
						